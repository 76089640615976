import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "../../components/Button";
import { Field } from "../../components/Field";
import { Form, FormActions, FormFooter, } from "../../components/Form";
import { FormStatusBanner } from "../../components/FormStatusBanner/FormStatusBanner";
import { Input } from "../../components/Input";
import { useLinkAsComponent } from "../../contexts/LinkAsComponent";
export const LoginForm = ({ onSubmit, defaultValues, validationSchema, loginError, }) => {
    const Link = useLinkAsComponent();
    return (_jsx(Form, Object.assign({ onSubmit: onSubmit, defaultValues: defaultValues, validationSchema: validationSchema }, { children: ({ errors, isSubmitting }) => {
            return (_jsxs(_Fragment, { children: [_jsx(FormStatusBanner, { isSubmitting: isSubmitting, error: loginError }), _jsx(Field, Object.assign({ label: "Email", labelFor: "email", error: errors.email }, { children: _jsx(Input, { name: "email", id: "email", type: "email" }) })), _jsx(Field, Object.assign({ label: "Password", labelFor: "password", error: errors.password }, { children: _jsx(Input, { name: "password", id: "password", type: "password" }) })), _jsx(FormActions, { children: _jsx(Button, Object.assign({ type: "submit", disabled: isSubmitting }, { children: isSubmitting ? "Logging in" : "Log in" })) }), _jsxs(FormFooter, { children: [_jsx(Link, Object.assign({ href: "/forgot-password" }, { children: "Forgot your password" })), "\u00A0or\u00A0", _jsx(Link, Object.assign({ href: "/signup" }, { children: "need to signup" })), "?"] })] }));
        } })));
};
