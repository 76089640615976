import { jsxs as _jsxs } from "react/jsx-runtime";
import invariant from "tiny-invariant";
import { Alert } from "../Alert";
export const FormStatusBanner = ({ error, success, isSubmitting, }) => {
    invariant(error && success ? false : true, "error and success are mutually exclusive");
    const status = isSubmitting && (error || success)
        ? error
            ? "error"
            : "success"
        : null;
    const statusMessage = error || success || null;
    return (status && (_jsxs(Alert, Object.assign({ variant: status, title: status === "error" ? "Error" : "Success" }, { children: [_jsxs("strong", { children: [status === "error" ? "Error" : "Success", ":"] }), " ", statusMessage] }))));
};
