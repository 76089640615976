import { styled, keyframes } from "../stitches.config";
const hoverIn = keyframes({
    from: { outlineWidth: 0 },
    to: { outlineWidth: "2" },
});
export const Input = styled("input", {
    fontFamily: "$normal",
    border: "$borderWidths$button solid $inputBorder",
    background: "$inputBackground",
    color: "$inputText",
    padding: "$2 $3",
    fontSize: "$2",
    width: "100%",
    "&:hover:not(:focus)": {
        outlineColor: "blue",
        outlineStyle: "solid",
        animation: `${hoverIn} 100ms ease-out`,
    },
    "&:focus": {
        outlineColor: "blue",
        outlineStyle: "solid",
        outlineWidth: "2px",
    },
    "&:disabled": {
        opacity: "0.6",
    },
    "&::-webkit-calendar-picker-indicator": {
        p: 0,
        pb: "$2",
    },
    "&[type='checkbox']": {
        position: "relative",
        appearance: "none",
        width: "$fontSizes$2",
        height: "$fontSizes$2",
        lineHeight: "$2",
        p: 0,
        "&:checked:before": {
            position: "absolute",
            top: -1,
            content: "✓",
            width: "$fontSizes$2",
            height: "$fontSizes$2",
            lineHeight: "$2",
        },
    },
});
