var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from "../stitches.config";
const FieldLabel = styled("label", {
    gridColumnStart: 1,
    gridColumnEnd: 2,
    pb: "$1",
});
const FieldError = styled("div", {
    display: "block",
    width: "100%",
    color: "$error",
    mt: "$1",
    gridColumnStart: 1,
    gridColumnEnd: 2,
});
const FieldContainer = styled("div", {
    display: "grid",
    width: "100%",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr",
    gap: "0",
    [`& + &`]: {
        mt: "$4",
    },
    variants: {
        inline: {
            true: {
                gap: "$space$1 $space$1",
                gridTemplateColumns: "auto 1fr",
                [`${FieldLabel}`]: {
                    gridColumnStart: 1,
                    gridColumnEnd: 1,
                },
            },
        },
    },
});
export const Field = (_a) => {
    var { label, labelFor, children, error } = _a, rest = __rest(_a, ["label", "labelFor", "children", "error"]);
    return (_jsxs(FieldContainer, Object.assign({}, rest, { children: [label && _jsx(FieldLabel, Object.assign({ htmlFor: labelFor }, { children: label })), children, error && _jsx(FieldError, { children: error })] })));
};
Field.toString = () => ".field";
