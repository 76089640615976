import { useCallback, useState } from "react";
import { useRouter } from "next/router";
import { GetServerSidePropsContext } from "next";

import { LoginForm } from "ui/dist/forms/LoginForm";
import { supabasePublic } from "../lib/supabasePublic";
import { serverSidePropsHandler } from "../utils/serverSidePropsHandler";
import { AppLayout } from "../components/AppLayout";
import { loginSchema } from "validation/dist/login";

const defaultValues = {
    email: "",
    password: "",
};

function LoginPage() {
    const router = useRouter();

    const [loginError, setLoginError] = useState("");

    const handleOnSubmit = useCallback(
        async ({ values, isValid, errors }) => {
            setLoginError("");

            if (isValid) {
                const { error } = await supabasePublic.auth.signIn(values, {
                    redirectTo: "/",
                });
                if (error) {
                    console.error(error);
                    setLoginError(error.message);
                } else {
                    await router.push("/home");
                }
            }
        },
        [router]
    );

    return (
        <LoginForm
            defaultValues={defaultValues}
            validationSchema={loginSchema}
            onSubmit={handleOnSubmit}
            loginError={loginError}
        />
    );
}

// eslint-disable-next-line react/display-name
LoginPage.getLayout = (page: React.ReactNode) => (
    <AppLayout title="Login" contained>
        {page}
    </AppLayout>
);

export default LoginPage;

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
    return serverSidePropsHandler({ ctx, redirectAuthenticated: true });
}
